import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
import TokenTable from "~/components/TokenTable/TokenTable";
import { default as typographyClasses } from 'tokens/build/js/semantic-typography';
import { Body, Heading, Display } from "@caesars-digital/caesars-ui";
export const _frontmatter = {
  "menuLabel": "Typography",
  "sortOrder": 5.5
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Typography`}</h1>
    <p className="p-large">Typography tokens help keep visual consistency for text formatting across themes.</p>
    <p>{`These tokens (in the form of CSS classes) should be applied to any text appearing in the app.  This allows for easy text formatting changes in the future as well as
consistent cross app / brand formatting.`}</p>
    <h3>{`Web developers have two options to apply Typography Tokens:`}</h3>
    <ul>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "/components/text"
        }}>{`Typography components`}</a>{` `}<inlineCode parentName="li">{`Display`}</inlineCode>{`, `}<inlineCode parentName="li">{`Heading`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Body`}</inlineCode>{`.  (These are no different from manually applying CSS classes, it's just a component that applies the same CSS classes with other convenient helper functionality)`}</li>
      <li parentName="ul">{`Use Typography CSS Classes. These are tied to each theme, and each theme might have different typography definitions for the classes. An example of a CSS class would be `}<inlineCode parentName="li">{`.display-3xl-bold`}</inlineCode>{`.`}</li>
    </ul>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Your Figma specs will reference which typography token to use when inspecting text.  In figma it might be represented as `}<inlineCode parentName="p">{`body/xl`}</inlineCode>{`, or `}<inlineCode parentName="p">{`heading/sm`}</inlineCode>{`.  Replace the `}<inlineCode parentName="p">{`/`}</inlineCode>{` character with a `}<inlineCode parentName="p">{`-`}</inlineCode>{` character for the CSS class.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Display component example:
<Display size="xl-bold">This would be text formatted by .display-xl-bold</Display>

// or you can apply the CSS Class to your own element
<div className="display-xl-bold">This text would be formatted the same as above.</div>
`}</code></pre>
    <hr></hr>
    <h2>{`Body`}</h2>
    <table className="cui-w-full mb-20">
  <tbody>
  <tr className="border-b-1 border-solid border-fg-moderate">
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Size</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">ClassName</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Example</th>
  </tr>
  {Object.entries(typographyClasses.body).map(([name, className]) => <tr key={`${name}-row`}>
      <td className="body-lg-bold cui-text-fg-moderate cui-font-bold py-2 px-4">{name}</td>
      <td className="cui-text-fg-moderate py-2 px-4"><code>{`.body-${name}`}</code></td>
      <td className="px-4 py-2"><Body size={name} tag="span" mdxType="Body">Be a Caesar!</Body></td>
    </tr>)}
  </tbody>
    </table>
    <h2>{`Heading`}</h2>
    <table className="cui-w-full mb-20">
  <tbody>
  <tr className="border-b-1 border-solid border-fg-moderate">
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Size</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">ClassName</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Example</th>
  </tr>
  {Object.entries(typographyClasses.heading).map(([name, className]) => <tr key={`${name}-row`}>
      <td className="body-lg-bold cui-text-fg-moderate cui-font-bold py-2 px-4">{name}</td>
      <td className="cui-text-fg-moderate py-2 px-4"><code>{`.heading-${name}`}</code></td>
      <td className="px-4 py-2"><Heading size={name} tag="span" mdxType="Heading">Be a Caesar!</Heading></td>
    </tr>)}
  </tbody>
    </table>
    <h2>{`Display`}</h2>
    <table className="cui-w-full mb-20">
  <tbody>
  <tr className="border-b-1 border-solid border-fg-moderate">
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Size</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">ClassName</th>
    <th className="text-left body-xl-bold px-4 pt-2 pb-5">Example</th>
  </tr>
  {Object.entries(typographyClasses.display).map(([name, className]) => <tr key={`${name}-row`}>
      <td className="body-lg-bold cui-text-fg-moderate cui-font-bold py-2 px-4">{name}</td>
      <td className="cui-text-fg-moderate py-2 px-4"><code>{`.display-${name}`}</code></td>
      <td className="px-4 py-2"><Display size={name} tag="span" mdxType="Display">Be a Caesar!</Display></td>
    </tr>)}
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      